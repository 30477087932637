import React from 'react';
import {navigate} from 'gatsby';

const Navigate = ({children, ...props}) => {
  const handleClick = (event) => {
    event.preventDefault();
    const path = new URL(event.currentTarget.href).pathname;
    navigate(path);
  };
  return <a {...props} onClick={handleClick}>{children}</a>;
};

export default Navigate;
